<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-form @submit.prevent="handleSubmit">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ $t("common.login") }}</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  :label="$t('common.email')"
                  name="email"
                  prepend-icon="person"
                  type="text"
                  v-model="email"
                ></v-text-field>

                <v-text-field
                  id="password"
                  :label="$t('common.password')"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                  v-model="password"
                ></v-text-field>
                <v-btn to="/forgot" text color="primary"
                  >{{ $t("user.forgotPassword") }}
                </v-btn>
              </v-card-text>
              <v-card-text
                style="
                  width: 90%;
                  margin: 0 auto;
                  border-radius: 10px;
                  background-color: rgb(238, 85, 85);
                  color: white;
                "
                v-if="errorMessage != null"
              >
                <h3 style="width: 90%; margin: 0 auto">{{ errorMessage }}</h3>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" type="button" @click="openTosDialog"
                  >{{ $t("common.login") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>

          <tos-dialog ref="tosDialog" :email="email" @accepted="handleSubmit" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TosDialog from "@/components/license/TosDialog.vue";

export default {
  name: "Login",
  components: {
    TosDialog,
  },

  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState("users", ["status", "userCompany", "currentUser"]),
  },
  created() {
    this.logout();
  },

  methods: {
    ...mapActions("users", ["login", "logout", "refreshUserData"]),

    openTosDialog() {
      if (this.email !== undefined && this.email !== "")
        this.$refs.tosDialog.openDialog();
    },

    async handleSubmit(e) {
      if (e !== undefined && typeof e === "object") e.preventDefault();

      this.submitted = true;
      const { email, password } = this;
      if (email && password) {
        try {
          await this.login({ email, password });
        } catch (e) {
          this.errorMessage = e;
        }
      }
    },
  },

  watch: {
    userCompany() {
      if (
        this.submitted &&
        Object.keys(this.currentUser).length !== 0 &&
        Object.keys(this.userCompany).length !== 0
      ) {
        this.$router.push("/landing");
      }
    },
  },
};
</script>
